import { render, staticRenderFns } from "./ObjectEdit.vue?vue&type=template&id=5ca9323c&scoped=true&"
import script from "./ObjectEdit.vue?vue&type=script&lang=ts&"
export * from "./ObjectEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca9323c",
  null
  
)

export default component.exports